import { makeConstant } from '../helper/typescript';
import env from '~/env.server';

import type { stringOrNumber } from 'types';

export default makeConstant({
  ACCOUNT_TYPES: `${env.AUTH_SERVICE_URL}/api/account_types`,
  ACTIVITIES_ID_URL: (activityId: stringOrNumber) => `${env.CLIENT_ACTIVITY_URL}/activities/${activityId}`,
  ACTIVITIES_URL: `${env.CLIENT_ACTIVITY_URL}/activities`,
  BITMOJI_REDIRECT_URL: '/auth/bitmoji/sso',
  BITMOJI_SSO_SUCCESS_URL: '/templates/bitmoji/ssoSuccessful.html',
  CHANNELS_ID_ACCESS_TOKEN_URL_FUNC: (mixtapeId: stringOrNumber) => `${env.API_URL}channels/${mixtapeId}/access_tokens`,
  CHANNELS_ID_RESPONSES_ID_URL_FUNC: (mixtapeId: stringOrNumber, mixtapeResponseId: stringOrNumber) =>
    `${env.API_URL}channels/${mixtapeId}/responses/${mixtapeResponseId}`,
  CHANNELS_ID_RESPONSES_ID_VIEWS_URL_FUNC: (mixtapeId: stringOrNumber, mixtapeResponseId: stringOrNumber) =>
    `${env.API_URL}channels/${mixtapeId}/responses/${mixtapeResponseId}/views`,
  CHANNELS_ID_RESPONSES_URL_FUNC: (mixtapeId: stringOrNumber) => `${env.API_URL}channels/${mixtapeId}/responses`,
  // This one can take a vanity token or an id.  If an id, it also returns the payload
  CHANNELS_ID_URL_FUNC: (mixtapeId: stringOrNumber) => `${env.API_URL}channels/${mixtapeId}`,
  CHANNELS_TOKEN_RESPONSE_TOKEN_URL_FUNC: (mixtapeToken: stringOrNumber, responseToken: stringOrNumber) =>
    `${env.API_URL}channels/${mixtapeToken}/responses/${responseToken}`,
  CLIENT_BITMOJI_LOGIN_REDIRECT_URL: '/auth/bitmoji/login',
  CLIENT_BITMOJI_TOKEN_REDIRECT_URL: '/auth/bitmoji/token',
  DISCOVERY_PROFILES_ME: `${env.API_URL}discovery/profiles/me`,
  EMAIL_SUBSCRIPTIONS_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}grids/${groupId}/email_subscriptions`,
  EMBED_VANITY_TOKENS_ID_URL_FUNC: (joinCode: stringOrNumber) => `${env.API_URL}embed/vanity_tokens/${joinCode}`,
  ENUMS_URL: `${env.API_URL}enums`,
  FEATURES_URL: `${env.API_URL}features`,
  FEEDBACKS_URL: `${env.API_URL}feedbacks`,
  GIPHY_BY_QUERY_ENDPOINT_URL_FUNC: (term: stringOrNumber) =>
    `https://api.giphy.com/v1/gifs/search?q=${term}&api_key=${env.GIPHY_API_KEY}&limit=50&rating=g`,
  GRID_TYPES: `${env.API_URL}grid_types`,
  GRID_RESPONSE_TRANSCRIPT_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}/transcript.vtt`,
  GRIDS_ID_MEMBERS_ID_URL_FUNC: (groupId: stringOrNumber, memberId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/members/${memberId}`,
  GRIDS_ID_MEMBERS_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}grids/${groupId}/members`,
  GRIDS_ID_RESPONSES_ID_TRANSCRIPT_TXT_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}/transcript.txt`,
  GRIDS_ID_RESPONSES_ID_URL_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}`,
  GRIDS_ID_RESPONSES_ID_VIEWS_URL_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}/views`,
  GRIDS_ID_RESPONSES_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}grids/${groupId}/responses`,
  GRIDS_ID_TOKENS_URL_FUNC: (resourceId: stringOrNumber) => `${env.API_URL}grids/${resourceId}/tokens`,
  GRIDS_ID_TOPICS_ID_TRANSCRIPT_TXT_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/topics/${topicId}/transcript.txt`,
  GRIDS_ID_TOPICS_ID_TRANSCRIPT_URL_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/topics/${topicId}/transcript`,
  GRIDS_ID_TOPICS_ID_URL_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/topics/${topicId}`,
  GRIDS_ID_TOPICS_ID_VIEWS_URL_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/topics/${topicId}/views`,
  GRIDS_ID_TOPICS_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}grids/${groupId}/topics`,
  GRIDS_ID_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}grids/${groupId}`,
  GRIDS_URL: `${env.API_URL}grids`,
  GRIDS_ID_PROVIDER_CALLBACK: (groupId: stringOrNumber, provider: string) =>
    `${env.API_URL}grids/${groupId}/${provider}/callback`,
  LANGUAGES_URL: `${env.API_URL}languages`,
  LIKE_URL_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}/likes`,
  LOGIN_SUCCESS_URL: '/templates/loginSuccessful.html',
  LTI_MESSAGES_TOKEN: (token: string) => `${env.API_URL}lti/messages/${token}`,
  MANAGE_ACHIEVEMENTS_URL: `${env.API_URL}manage/achievements`,
  MANAGE_ACHIEVEMENT_GROUPS_URL: `${env.API_URL}manage/achievement_groups`,
  MANAGE_CHANNELS_ID_URL_FUNC: (mixtapeId: string) => `${env.API_URL}manage/channels/${mixtapeId}`,
  MANAGE_CHANNELS_ID_RESPONSES_URL_FUNC: (mixtapeId: string) => `${env.API_URL}manage/channels/${mixtapeId}/responses`,
  MANAGE_CHANNELS_ID_RESPONSES_ID_URL_FUNC: (mixtapeId: string, mixtapeResponseId: stringOrNumber) =>
    `${env.API_URL}manage/channels/${mixtapeId}/responses/${mixtapeResponseId}`,
  MANAGE_CHANNELS_URL: `${env.API_URL}manage/channels`,
  MANAGE_COLLECTIONS: `${env.API_URL}manage/collections`,
  MANAGE_COLLECTION_ITEMS: `${env.API_URL}manage/collection_items`,
  MANAGE_COLLECTIONS_ID_COLLECTION_ITEMS_URL_FUNC: (collectionId?: string | number, isPublic = false) =>
    `${env.API_URL}${!isPublic && 'manage/'}collections/${collectionId}/collection_items`,
  MANAGE_DOWNLOADS_URL_FUNC: `${env.API_URL}manage/downloads`,
  MANAGE_GOOGLE_COURSES: `${env.API_URL}manage/google/courses`,
  MANAGE_GOOGLE_GRIDCONNECTIONS_ID: (connectionId: stringOrNumber) =>
    `${env.API_URL}manage/google/grid_connections/${connectionId}`,
  MANAGE_GOOGLE_TOKENS_ME: `${env.API_URL}manage/google/tokens/me`,
  MANAGE_GRID_INVITES: `${env.API_URL}manage/grid_invites`,
  MANAGE_GRIDS_ID_CLONE_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/clone`,
  MANAGE_GRIDS_ID_MEMBERS_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/members`,
  MANAGE_GRIDS_ID_RESPONSES_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/responses`,
  MANAGE_GRIDS_ID_REPORTS_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/reports`,
  MANAGE_GRIDS_ID_RESPONSES_ID_URL_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}manage/grids/${groupId}/responses/${responseId}`,
  MANAGE_GRIDS_ID_RESPONSES_ID_TRANSCRIPT_TXT: (apiUrl: string, groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${apiUrl}manage/grids/${groupId}/responses/${responseId}/transcript.txt`,
  MANAGE_GRIDS_ID_RESPONSES_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/responses`,
  MANAGE_GRIDS_ID_STUDENTS_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/students`,
  MANAGE_GRIDS_ID_STUDENT_ID_URL_FUNC: (groupId: stringOrNumber, memberId: stringOrNumber) =>
    `${env.API_URL}manage/grids/${groupId}/students/${memberId}`,
  MANAGE_GRIDS_ID_TOPICS_ID_INTEGRATIONS_ID_URL_FUNC: (
    groupId: stringOrNumber,
    topicId: stringOrNumber,
    integrationId: stringOrNumber,
  ) => `${env.API_URL}manage/grids/${groupId}/topics/${topicId}/integrations/${integrationId}`,
  MANAGE_GRIDS_ID_TOPICS_ID_INTEGRATIONS_URL_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}manage/grids/${groupId}/topics/${topicId}/integrations`,
  MANAGE_GRIDS_ID_TOPICS_ID_URL_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}manage/grids/${groupId}/topics/${topicId}`,
  MANAGE_GRIDS_ID_TOPICS_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/topics`,
  MANAGE_GRIDS_ID_TYPE_CSV_FUNC: (groupId: stringOrNumber, type: string) =>
    `${env.API_URL}manage/grids/${groupId}/${type}.csv`,
  MANAGE_GRIDS_ID_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}`,
  MANAGE_GRIDS_ID_USERS_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/users`,
  MANAGE_GRIDS_ID_USERS_ACCEPT_URL_FUNC: (groupId: stringOrNumber) =>
    `${env.API_URL}manage/grids/${groupId}/users/accept`,
  MANAGE_GRIDS_ID_USERS_USERS_ID_URL_FUNC: (groupId: stringOrNumber, userId: stringOrNumber) =>
    `${env.API_URL}manage/grids/${groupId}/users/${userId}`,
  MANAGE_GRIDS_ID_USERS_URL_FUNC: (groupId: stringOrNumber) => `${env.API_URL}manage/grids/${groupId}/users`,
  MANAGE_GRID_DEFAULTS_URL: `${env.API_URL}manage/grid_defaults`,
  MANAGE_JOBS_URL: (jobId: stringOrNumber) => `${env.API_URL}manage/jobs/${jobId}`,
  MANAGE_LTI_OATH_CLIENTS: `${env.API_URL}manage/lti/oauth_clients`,
  MANAGE_LTI_OATH_CLIENTS_ID: (ltiKeyId: stringOrNumber) => `${env.API_URL}manage/lti/oauth_clients/${ltiKeyId}`,
  MANAGE_LTI_RESOURCE_CONTEXTS: `${env.API_URL}manage/lti/resource_contexts`,
  MANAGE_MOVE_RESPONSES_URL: `${env.API_URL}manage/responses/move`,
  MANAGE_RESPONSES_ID: (responseId: stringOrNumber) => `${env.API_URL}manage/responses/${responseId}`,
  MANAGE_RESPONSES_ID_CHANNELS_URL_FUNC: (responseId: stringOrNumber) =>
    `${env.API_URL}manage/responses/${responseId}/channels`,
  MANAGE_RESPONSES_ID_CLONE_URL_FUNC: (responseId: stringOrNumber) =>
    `${env.API_URL}manage/responses/${responseId}/clone`,
  MANAGE_RESPONSES_ID_TRANSCRIPTS_URL_FUNC: (responseId: stringOrNumber) =>
    `${env.API_URL}manage/responses/${responseId}/transcripts`,
  MANAGE_STATS_URL: `${env.API_URL}manage/stats`,
  MANAGE_TOPIC_SETTINGS: `${env.API_URL}/manage/topic_settings`,
  MANAGE_TOPICS_CLONE_URL: `${env.API_URL}manage/topics/clone`,
  MANAGE_TOPICS_ID_URL_FUNC: (topicId: stringOrNumber) => `${env.API_URL}manage/topics/${topicId}`,
  MANAGE_TOPICS_SETTINGS_URL: `${env.API_URL}manage/topic_settings`,
  MANAGE_TOPICS_URL: `${env.API_URL}manage/topics`,
  MANAGE_TOPIC_TEMPLATES_ID_URL: (topicId: stringOrNumber) => `${env.API_URL}manage/topic_templates/${topicId}`,
  MANAGE_TOPIC_TEMPLATES_URL: `${env.API_URL}manage/topic_templates`,
  MANAGE_USER_NOTIFICATIONS: `${env.API_URL}manage/user_notifications`,
  MANAGE_VANITY_TOKENS_VALIDATE_TOKEN: `${env.API_URL}manage/vanity_tokens/validate`,
  MIXTAPE_TRANSCRIPT_FUNC: (mixtapeId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}channels/${mixtapeId}/responses/${responseId}/transcript.vtt`,
  MIXTAPE_TRANSCRIPT_TXT_FUNC: (mixtapeId: stringOrNumber, responseId: stringOrNumber, apiUrl?: string) =>
    `${apiUrl || env.API_URL}channels/${mixtapeId}/responses/${responseId}/transcript.txt`,
  PRIVATE_SHARE_REPORTS_TOKEN_FUNC: (token: stringOrNumber) => `${env.API_URL}reports/${token}`,
  PRIVATE_SHARE_GET_TOKEN_FUNC: (token: stringOrNumber) => `${env.API_URL}responses/${token}/share_tokens`,
  RECORDING_STATS_URL: `${env.API_URL}recording_stats`,
  PRIVATE_SHARE_TRANSCRIPT_FUNC: (reportId: string, responseId: string) =>
    `${env.API_URL}reports/${reportId}/responses/${responseId}/transcript.vtt`,
  PRIVATE_SHARE_TRANSCRIPT_TXT_FUNC: (reportId: string, responseId: string) =>
    `${env.API_URL}reports/${reportId}/responses/${responseId}/transcript.txt`,
  REPORT_GROUP: (groupId: stringOrNumber) => `${env.API_URL}grids/${groupId}/flags`,
  REPORT_RESPONSE: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}/flags`,
  REPORT_TOPIC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/topics/${topicId}/flags`,
  REPORTS_ID_VIEWS: (reportId: stringOrNumber) => `${env.API_URL}reports/${reportId}/views`,
  REPORTS_TOKEN: (token: string) => `${env.API_URL}reports/${token}`,
  RESPONSES_ID_TRANSCRIPT_TXT_FUNC: (responseId: stringOrNumber, apiUrl?: string) =>
    `${apiUrl || env.API_URL}responses/${responseId}/transcript.txt`,
  RESPONSES_ID_URL_FUNC: (responseId: stringOrNumber) => `${env.API_URL}responses/${responseId}`,
  RESPONSES_URL: `${env.API_URL}responses`,
  RESPONSE_TRANSCRIPT_FUNC: (responseId: stringOrNumber) => `${env.API_URL}responses/${responseId}/transcript.vtt`,
  RESPONSES_ID_TRANSCRIPTS_URL_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}/transcripts/regenerate`,
  SELF_SERVICE_DATA_EXPORTS_URL: `${env.API_URL}self_service/data_exports`,
  SNAPCHAT_AUTH_ENDPOINT: 'https://accounts.snapchat.com/accounts/oauth2/token',
  STICKERS_CATEGORIES_URL: `${env.API_URL}sticker_categories`,
  STICKERS_URL: `${env.API_URL}stickers`,
  STOCK_IMAGES_URL: `${env.API_URL}stock_images`,
  STUDENTS_ME_URL: `${env.API_URL}students/me`,
  TEMPLATES: `${env.API_URL}topic_templates`,
  TIMEZONES: `${env.API_URL}timezones`,
  TOKENS_URL: (authServiceUrl?: string) => `${authServiceUrl || env.AUTH_SERVICE_URL}/api/tokens`,
  TOPICS_ID_TOKENS_URL_FUNC: (resourceId: stringOrNumber) => `${env.API_URL}topics/${resourceId}/tokens`,
  TOPICS_ID_PROVIDER_CALLBACK: (topicId: stringOrNumber, provider: string) =>
    `${env.API_URL}topics/${topicId}/${provider}/callback`,
  TOPICS_URL: `${env.API_URL}topics`,
  TOPICS_SUGGESTIONS: `${env.API_URL}topics/suggestions`,
  TOPIC_TRANSCRIPT_FUNC: (groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/topics/${topicId}/transcript.vtt`,
  // this is used client-side so I need to pass in the base ApiUrl
  TOPIC_TRANSCRIPT_TXT: (apiUrl: string, groupId: stringOrNumber, topicId: stringOrNumber) =>
    `${apiUrl}grids/${groupId}/topics/${topicId}/transcript.txt`,
  TRANSCRIPT_GRID_RESPONSE_FUNC: (groupId: stringOrNumber, responseId: stringOrNumber) =>
    `${env.API_URL}grids/${groupId}/responses/${responseId}/transcript`,
  TRANSCRIPT_RESPONSE_FUNC: (responseId: stringOrNumber) => `${env.API_URL}responses/${responseId}/transcript`,
  UPLOADS_URL: `${env.CLIENT_API_URL}uploads`,
  USER_DELETION_REASONS: `${env.AUTH_SERVICE_URL}/api/enum/user_deletion_reasons`,
  USER_DELETIONS: `${env.AUTH_SERVICE_URL}/api/user_deletions`,
  USER_UNSUBSCRIBE: `${env.API_URL}unsubscribe`,
  USERS_ME_URL: `${env.CLIENT_AUTH_SERVICE_URL}/api/users/me`,
  VANITY_TOKENS_ID_URL_FUNC: (joinCode: stringOrNumber) => `${env.API_URL}vanity_tokens/${joinCode}`,
  WEB_SOCKET_API_ENDPOINT: `${env.API_URL}ws`,
  WEB_SOCKET_CLIENT_ID: `${env.WEB_SOCKET_CLIENT_ID}`,
  WEB_SOCKET_URL: `${env.WEB_SOCKET_URL}`,
});
